.projects {
  padding: 70px 0;
  background-color: white;
  text-align: center;
}

.projects .projectRowWrapper {
  margin-bottom: 40px;
}

.projects .moreProjectsButton {
  display: inline-block;
  font-size: 22px;
  padding: 6px 30px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: var(--dark-blue);
  transition: all 0.1s ease-in-out;
}

.projects .moreProjectsButton:hover {
  background-color: var(--blue);
}

@media (max-width: 767px) {
}
