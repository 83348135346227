@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("./static/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

:root {
  --light-blue: #eef7fa;
  --blue: rgb(70, 120, 160);
  --blue-transparent: rgba(70, 120, 160, 0.8);
  --dark-blue: #3e6b8e;

  --dark-black: #000204;
  --black: #1a1a1a;

  --whitesmoke: whitesmoke;
  --smoke: #f0f0f0;
  --gray: #e8e8e8;
  --dark-gray: #e0e0e0;

  --font1: "Lato";
  --font2: "Poppins";

  --header-height: 45px;
  --footer-height: 70px;
}

body,
html {
  overflow-x: hidden;
  background-color: var(--black);
  margin: 0;
  padding: 0;
  max-width: 100%;
  font-family: var(--font1);
  scroll-behavior: smooth;
}

a {
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

.tag {
  display: inline-block;
  font-size: 12px;
  border-radius: 8px;
  padding: 3px 6px;
  margin: 3px 6px 3px 0;
}

.languageTag {
  background-color: lightsalmon;
}

.toolTag {
  background-color: lightblue;
}

.blueLink {
  color: blue !important;
}

@media (max-width: 575px) {
  .tag {
    font-size: 10px;
    border-radius: 8px;
    padding: 1px 6px;
  }
}
