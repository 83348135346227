.footer {
    height: var(--footer-height);
    display: flex;
    align-items: center;
    background-color: var(--black);
    color: var(--dark-gray);
    text-align: center;
    z-index: 3;
}

.footer .footerIcon {
    display: inline-block;
    padding: 0 35px;
    font-size: 30px;
    transition: all 0.1s ease-in-out;
}

.footer .footerIcon:hover {
    color: var(--blue);
    transform: scale(1.2);
}

.footer .footerCopyright {
    padding-top: 10px;
}

@media (max-width: 575px) {
    .footer .footerIcon {
        padding: 0 24px;
        font-size: 25px;
    }
}
