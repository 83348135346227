.loadingScreen {
  background-color: var(--light-blue);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hidden {
  display: none;
}

.home {
  background-color: white;
}

.home .landingScreen {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home .backgroundVideoWrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.home .backgroundVideoWrap video {
  min-width: 100%;
  min-height: 100%;
}

.home .videoOverlay {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  background: white;
  opacity: 0.3;
}

.home .landingContent {
  margin-bottom: 100px;
  width: 70%;
  z-index: 2;
}

.home .landingTitle {
  font-family: var(--font2);
  font-size: 90px;
  font-weight: bolder;
}

.home .landingSubtitle {
  padding-top: 5px;
  font-family: var(--font2);
  font-size: 30px;
}

.home .landingArrow {
  font-size: 50px;
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
}

.home .landingArrow button {
  background: none;
  border: none;
  margin: 60px 100px;
}

.home .landingArrow button:focus {
  outline: none;
  color: var(--blue);
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 60px;
  }
  50% {
    bottom: 70px;
  }
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .home .landingTitle {
    font-size: 60px;
  }
}

@media (max-width: 575px) {
  .home .landingContent {
    width: 90%;
  }

  .home .landingTitle {
    font-size: 48px;
  }

  .home .landingSubtitle {
    font-size: 22px;
  }
}

@media (max-height: 635px) {
  .home .landingContent {
    margin-bottom: 0;
  }

  .home .landingArrow {
    visibility: hidden;
  }
}
