.projectRow {
  position: relative;
  overflow-y: hidden;
  font-size: 20px;
  padding: 20px 20px;
  border-radius: 10px;
  text-align: left;
  background-color: var(--whitesmoke);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.projectRow:hover {
  background-color: var(--smoke);
}

/* Project row buttons */
.projectRow .projectRowButtons {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  margin: 12px 20px;

  transform: translateY(-150%);
  transition: transform 0.5s;
}

.projectRow:hover .projectRowButtons {
  transform: translateY(0px);
}

.projectRow:focus-within .projectRowButtons {
  transform: translateY(0px);
}

.projectRow .projectRowButtons .projectRowButton {
  cursor: pointer;
  font-size: 18px;
  width: 45px;
  height: 45px;
  border: 1px solid var(--light-blue);
  border-radius: 50%;
  margin-left: 15px;
  background-color: white;
  color: var(--blue);
  transition: 0.15s ease-in;

  display: flex;
  align-items: center;
  justify-content: center;
}

.projectRow .projectRowButtons .projectRowButton:hover {
  background-color: var(--blue);
  color: white;
}

/* Project row content */
.projectRow .projectRowTitle {
  display: inline-block;
  cursor: pointer;
  font-size: 30px;
}

.projectRow .projectRowLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectRow .projectImageAndButtons {
  width: 100%;
  max-height: 100%;
  text-align: center;
}

.projectRow .projectImage {
  width: 90%;
  height: auto;
  cursor: pointer;
  border: 1px solid var(--blue);
  border-radius: 10px;
}

@media (max-width: 991px) {
  .projectRow {
    font-size: 17px;
  }

  .projectRow .projectRowButtons .projectRowButton {
    font-size: 16px;
    width: 40px;
    height: 40px;
  }

  .projectRow .projectImage {
    width: 100%;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  .projectRow {
    font-size: 15px;
  }

  .projectRow .projectRowButtons {
    margin: 12px 12px;
  }

  .projectRow .projectRowButtons .projectRowButton {
    font-size: 14px;
    width: 35px;
    height: 35px;
    margin-left: 5px;
  }

  .projectRow .projectRowTitle {
    font-size: 25px;
  }
}
