.allProjects {
  margin-top: var(--header-height);
  padding: 30px 0 0px 0;
  background-color: white;
  font-size: 20px;
}

.allProjects .projectRowWrapper {
  margin: 0px 0 40px 0;
}

.allProjects .moreProjects {
  background-color: var(--whitesmoke);
  padding: 20px;
  margin: 20px 0 0 0;
  text-align: center;
}

@media (max-width: 991px) {
  .allProjects {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .allProjects {
  }
}

@media (max-width: 575px) {
  .allProjects {
  }
}
