.header {
  position: fixed;
  top: 0;
  width: 100%;
  font-size: 16px;
  background-color: var(--black);
  height: var(--header-height);
  z-index: 3;
  border-bottom: 3px var(--blue) solid;
  user-select: none;

  display: flex;
  align-items: center;
}

.header .headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo {
  font-family: var(--font2);
  color: white;
  font-weight: bold;
  white-space: nowrap;
}

.header .navLink {
  display: inline-block;
  color: white;
}

.header .navLink:not(:last-child) {
  margin-right: 20px;
}

.header .navLink div {
  border-bottom: 3px solid transparent;
}

.header .headerContent .navLink-active div {
  transition: all 0.15s ease-in-out;
  border-bottom: 3px solid var(--blue);
}

@media (max-width: 575px) {
  .header .navLink {
    font-size: 13px;
  }
  .header .navLink:not(:last-child) {
    margin-right: 10px;
  }
}
