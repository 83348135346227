.about {
  background-color: var(--whitesmoke);
  padding: 70px 0;
  font-size: 22px;
}

.about .portraitWrapper {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 5px 0;
}

.about .portrait {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

@media (max-width: 991px) {
  .about {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .about .portraitWrapper {
    padding: 0 0 20px 0;
  }

  .about .portrait {
    width: 60%;
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .about .portrait {
    width: 80%;
  }
}
