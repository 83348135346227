.timeline {
  padding: 70px 0;
  background-color: var(--whitesmoke);
  text-align: center;

  --time-column-width: 200px;
  --logo-column-width: 100px;
}

.timeline .timelineRow {
  display: flex;
  text-align: left;
}

.timeline .timelineRow .dateColumn {
  position: relative;
  width: var(--time-column-width);
  text-align: right;
  padding-right: 30px;
  border-right: 1px solid var(--dark-gray);
}

.timeline .timelineRow .dateColumn .date {
  line-height: 1;
  transform: translateY(-5px);

  font-style: italic;
  color: gray;
}

.timeline .timelineRow .dateColumn .dateMark {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(55%);

  display: inline-block;
  width: 9px;
  height: 9px;
  background-color: var(--blue);
  border-radius: 50%;
}

.timeline .timelineRow:hover .dateColumn .dateMark {
  background-color: var(--dark-blue);
}

.timeline .timelineRow .positionColumn {
  transform: translateY(-5px);

  width: calc(100% - var(--time-column-width));
  padding-bottom: 30px;
  /* border-bottom: 1px solid var(--dark-gray); */
  margin: 0 30px 30px 30px;
  display: flex;
}

.timeline .timelineRow .positionColumn-last {
  border-bottom: none;
  margin-bottom: 0;
}

.timeline .timelineRow .positionColumn .logoWrapper {
  width: var(--logo-column-width);
  min-width: var(--logo-column-width);
  padding-right: 30px;
}

.timeline .timelineRow .positionColumn .logoWrapper .companyLogo {
  width: 100%;
}

.timeline .timelineRow .positionColumn .positionCompany {
  line-height: 1;
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: bold;
}

.timeline .timelineRow .positionColumn .positionCompany .positionDate-mobile {
  display: none;
}

.timeline .timelineRow .positionColumn .positionName {
  font-size: 17px;
  color: gray;
}

.timeline .timelineRow .positionColumn .positionDscription {
  font-size: 17px;
}

.timeline .timelineRow .positionColumn .positionTags {
  padding-top: 5px;
}

.timeline .expandTimelineButton {
  display: inline-block;
  margin-top: 10px;
  font-size: 22px;
  padding: 6px 30px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: var(--dark-blue);
  transition: all 0.1s ease-in-out;
}

.timeline .expandTimelineButton:hover {
  background-color: var(--blue);
}

@media (max-width: 991px) {
  .timeline {
    --time-column-width: 170px;
    --logo-column-width: 80px;
  }

  .timeline .timelineRow .dateColumn {
    padding-right: 20px;
    font-size: 14px;
  }

  .timeline .timelineRow .positionColumn {
    padding-bottom: 20px;
    margin: 0 20px 20px 20px;
  }

  .timeline .timelineRow .positionColumn .logoWrapper {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .timeline {
  }

  .timeline .timelineRow {
    display: block;
  }

  .timeline .timelineRow .dateColumn {
    display: none;
  }

  .timeline .timelineRow .positionColumn {
    transform: none;
    width: 100%;
    padding-bottom: 20px;
    margin: 0 0 20px 0;
  }

  .timeline .timelineRow .positionColumn-last {
    margin-bottom: 0;
  }

  .timeline .timelineRow .positionColumn .logoWrapper {
  }

  .timeline .timelineRow .positionColumn .positionInfo {
    flex: 1;
  }

  .timeline .timelineRow .positionColumn .positionCompany {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .timeline .timelineRow .positionColumn .positionCompany .positionDate-mobile {
    display: block;
    color: gray;
    font-size: 14px;
    padding-top: 5px;
  }
}

@media (max-width: 575px) {
  .timeline {
  }

  .timeline .timelineRow .positionColumn .positionCompany {
    font-size: 20px;
  }

  .timeline .timelineRow .positionColumn .positionName {
    font-size: 15px;
  }

  .timeline .timelineRow .positionColumn .positionDscription {
    font-size: 15px;
  }
}
